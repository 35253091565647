<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.roles') }}</h5>
                <DataTable :value="items" filterDisplay="row" v-model:filters="tableFilter" :rows="perPage" :loading="loading" class="p-mt-3" ref="datatable" responsiveLayout="scroll">
                    <template #header>
                        <div class="p-grid">
                            <div class="p-input-icon-left p-col-12 p-md-2">
                                <i class="pi pi-search" />
                                <InputText v-model="tableFilter['global'].value" :placeholder="$t('search.byName')" style="width: 100%" />
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-8 p-text-right"><Button :label="$t('buttons.add')" class="p-button-raised p-button-success" @click="goto(editPage, { id: 0 })" /></div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="displayName" :header="$t('sportsSettings.displayName')" :sortable="true"></Column>
                    <Column field="userCount" :header="$t('system.userCount')" :sortable="true"></Column>
                    <Column field="active" :header="$t('general.status')" :style="{ width: '200px' }">
                        <template #body="{ data }">
                            <span :class="'custom-badge status-' + data.active">{{ data.active ? $t('general.active') : $t('general.inactive') }}</span>
                        </template>
                    </Column>
                    <Column :exportable="false" class="p-text-right" v-show="hasAccess(availableMenus, editPage)">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="goto(editPage, { id: slotProps.data._id })" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';
import { BoApi } from '../../service/BoApi';
import { uiSettings } from '../../settings/uiSettings';
export default {
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            BoApi: new BoApi(),
            searchTerm: '',
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            crtRoute: 'roles',
            tableFilter: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
        };
    },
    computed: {
        editPage() {
            return this.crtRoute + '/edit';
        },
        availableMenus() {
            return this.$store.getters['user/availableMenus'];
        },
    },
    mounted() {
        this.BoApi.getRoles()
            .then((response) => {
                this.items = response.data;
                this.loading = false;
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
            });
    },
};
</script>